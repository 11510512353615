.gallery{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
}

.images{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.photo{
    width: 300px;
    height: auto;
}

.btn-image{
    cursor: pointer;
    padding: 0;
    border:none;
    margin-bottom: 15px;
}

@media screen and (max-width: 767px){ 
    .images{
        width: auto;
    }

}