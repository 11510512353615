.navbar {
  background-color: #ebebeb;
}

html {
  scroll-behavior: smooth;
}


.logo {
  height: 120px;
  width: 120px;
  margin-top: 15px;
}

.navbar-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  height: 80px;
}


.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.navbar li {
  margin: 0;
  padding: 0;
}

.navbar a {
  display: block;
  color: #573C09;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;

}

.menu-responsive {
  display: none;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.nav-option {
  display: inline-block;
  padding: 10px;
  transition: transform 0.2s ease;
}

.nav-option:hover {
  transform: scale(1.2);
}


@media screen and (max-width: 767px) {
  .menu-responsive {
    display: block;
  }

  .menu {
    display: none !important;
  }

  .sombra {
    width: 350px;
    height: auto;
  }

.nav-menu {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute !important;
    top: 75px !important;
    opacity: 1 !important;
    transition: all 0.5s ease !important;
    background-color: #ebebee !important;
    width: 100% !important;
    height: 105% !important;
    left: -145% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .navbar{
    overflow: hidden !important;
    position: fixed !important;
    width: 100%;
  }
  
  .nav-menu-active {
    height: 105% !important; 
    left: 0% !important;
    overflow: auto !important;
    position: fixed !important;
    width: 100%;
  }

  nav ul li a {
    position: relative;
  }
  
  nav ul li a:before {
    content: '' !important;
    position: absolute !important;
    width: 0% !important;
    height: 2px !important;
    bottom: 0 !important;
    left: 0 !important;
    background-color: #322203 !important;
    visibility: hidden !important;
    transition: all 0.3s ease-in-out !important;
  }
  
  nav ul li a:hover:before {
    visibility: visible;
    width: 100%;
  }

  li{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ebebeb;
  }

  a{
    font-size: 40px;
    color: #322203;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }

  .sombra{
    margin-bottom: 20px;
    width: 340px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Agrega una sombra a la derecha y abajo del div */
  }
}