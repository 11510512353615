.container-about{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.about{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 400px;
}

.photos-about{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.company-name, .titulo-encabezado{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 30px;
}

.description{
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 20px;
    text-align: justify;
}

.photo-1, .photo-2{
    height: auto;
    width: 360px;
}


@media screen and (max-width: 767px){
    .container-about{
        flex-direction: column;
    }

    .about{
        width: 350px;
    }
  }