.contact{
    padding-top: 50px;
    padding-bottom: 50px;
    height: auto;
}

.titulo-contacto{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
}

.a-numero{
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    color: #322203;
    font-size: 30px;
}

.logo-condos{
    height: 350px;
    width: 350px;
}

.numbers-logo{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.social-media{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 330px;
    margin-left: auto;
    margin-right: auto;
}

.circle-media{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 767px){
    .numbers-logo{
        flex-direction: column;
    }

}