
.frontpage{
    background-image: url(../../Images/FRONTPAGE.webp);
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.frontpage-text{
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}



.encabezado-frontpage{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

.button-reserva {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    color: #555555;
    background-color: #ebebeb;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }
  
  .button-reserva:hover {
    background-color: #fff;
  }

  @media screen and (max-width: 767px){
    .frontpage{
        background-image: url('../../Images/Background-Mobile.jpg');
        background-repeat: no-repeat;
    }

    .encabezado-frontpage{
        text-align: center;
    }
  }
  